<ll-titulo>
    <ng-template [ngIf]="evaluacionInstancia">Resultados evaluación para {{ nombreUsuario }}</ng-template>
</ll-titulo>
<ll-subtitulo>
    <ng-template [ngIf]="evaluacionInstancia"></ng-template>
</ll-subtitulo>

<respuestas-ensayo
    [evaluacionInstanciaId]="evaluacionInstanciaId"
    (onEvaluacionInstanciaReady)="onEvaluacionInstanciaReady($event)"
    [showClasificaciones]="false"
    [withCurso]="false"
    [enableDesempeno]="false"
    [enableDudas]="false"
    [enableReporte]="false"
    [disableTabs]="true"
></respuestas-ensayo>

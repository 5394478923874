<ll-titulo>Estadística Anuies</ll-titulo>

<estadistica-usuarios-preguntas-flex
    [rutaInstancias]="'/ensayo_instancias_estudiante'"
    (generadorInstrumentoChanged)="setData($event)"
></estadistica-usuarios-preguntas-flex>
<br />
<loading-layout #loadingLayout>
    <listado-preguntas-mas-incorrectas
        *ngIf="dataRespuestas"
        [dataFull]="dataRespuestas"
        [useLongerNumberCell]="true"
        [pageSize]="20"
    ></listado-preguntas-mas-incorrectas>
</loading-layout>

import { Component, Input, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { AuthService } from "@puntaje/shared/core"

@Component({
    selector: "landing",
    templateUrl: "landing.component.html",
    styleUrls: ["landing.component.scss"]
})
export class LandingComponent implements OnInit {
    @Input() enableRegister = false
    config: typeof config
    landingImage: string = "https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/shared/base/fondo_base.jpg"
    logo: string
    logoFooter: string
    titulo: string

    constructor(private router: Router, private route: ActivatedRoute, public authService: AuthService) {}

    ngOnInit() {
        this.config = config
        if (this.authService.isLoggedIn()) {
            this.router.navigate(["home"])
        }

        this.landingImage = this.config.app.baseStyle?.landing?.backgroundImage
            ? this.config.app.baseStyle?.landing?.backgroundImage
            : "https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/shared/base/fondo_base.jpg"

        this.titulo = this.config.app.baseStyle?.landing?.subtitle ? this.config.app.baseStyle?.landing?.subtitle : ""

        this.logo = this.config.app.baseStyle?.landing?.logo
            ? this.config.app.assets[this.config.app.baseStyle?.landing?.logo]
            : this.config.app.assets.logoLargeLight

        this.logoFooter = this.config.app.baseStyle?.landing?.logoFooter
            ? this.config.app.assets[this.config.app.baseStyle?.landing?.logoFooter]
            : this.config.app.assets.logoMediumLight
    }
}

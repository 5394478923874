import { Component, OnDestroy, OnInit, ViewChild, Output, EventEmitter } from "@angular/core"
import { Subscription } from "rxjs"
import { ActivatedRoute } from "@angular/router"
import { EvaluacionInstancia } from "@puntaje/puntaje/api-services"

@Component({
    templateUrl: "resultados_ensayo_alumno.component.html"
})
export class ResultadosEnsayoAlumnoComponent implements OnInit, OnDestroy {
    evaluacionInstanciaId: number
    evaluacionInstancia: EvaluacionInstancia
    sub: Subscription
    subNombre: Subscription
    nombreUsuario: string

    @Output() onEvaluacionReady: EventEmitter<any> = new EventEmitter<any>()

    constructor(protected route: ActivatedRoute) {}

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            this.evaluacionInstanciaId = +params["id"]
        })
        this.subNombre = this.route.queryParams.subscribe(params => {
            this.nombreUsuario = params["nombre"]
        })
    }

    onEvaluacionInstanciaReady(evaluacionInstancia) {
        this.evaluacionInstancia = evaluacionInstancia
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
        this.subNombre.unsubscribe()
    }
}

import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Alternativa, Alternativas, GeneradorInstrumento, GeneradorInstrumentos } from "@puntaje/nebulosa/api-services"
import { PaginatorComponent } from "@puntaje/shared/core"
import { EvaluacionInstancia, EvaluacionInstancias } from "@puntaje/puntaje/api-services"

@Component({
    templateUrl: "./estadistica-usuarios-preguntas-flex.component.html",
    styleUrls: ["./estadistica-usuarios-preguntas-flex.component.scss"],
    selector: "estadistica-usuarios-preguntas-flex"
})
export class EstadisticaUsuariosPreguntasFlexComponent {
    @Input() evaluacionTipo: string = "Anuies"
    @Input() rutaInstancias: string = "/ensayo_instancias"
    generadorInstrumento: GeneradorInstrumento
    instancias: EvaluacionInstancia[]
    respuestasPorPregunta: any = {}
    dummyPreguntas
    loadingDone = false

    @ViewChild(LoadingLayoutComponent, { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild(PaginatorComponent, { static: true }) paginator: PaginatorComponent
    @Output() generadorInstrumentoChanged = new EventEmitter<any>()

    constructor(
        protected evaluacionInstanciaService: EvaluacionInstancias,
        protected generadorInstrumentoService: GeneradorInstrumentos,
        protected alternativaService: Alternativas
    ) {}

    async ngOnInit() {
        const params = {
            tipo_instrumento: {
                tipo_instrumento: this.evaluacionTipo
            },
            sort: [{ field: "created_at", criteria: "desc" }],
            include: "asignatura_plataforma",
            with_tipo_instrumento: 1
        }
        const generadores = await this.generadorInstrumentoService.where(params)
        if (generadores.length > 0) {
            this.generadorInstrumento = generadores[0]
            this.generadorInstrumentoChanged.emit(this.generadorInstrumento)
            this.dummyPreguntas = new Array(generadores[0].numero_preguntas)
        }
    }

    getInstancias = async (page: number = 1, per: number = 10) => {
        this.loadingLayout.standby()
        const instanciaParams = {
            include: "[usuario,respuestas,evaluacion:[instrumento:instrumento_preguntas]]",
            generador_instrumento_id: this.generadorInstrumento.id,
            page: page,
            per: per,
            evaluacion_instancia: {
                oficial: 1
            }
        }
        this.evaluacionInstanciaService.enableIgnoreModel()
        const total = (await this.evaluacionInstanciaService.porGenerador({
            ...instanciaParams,
            ...{ count: 1 }
        })) as unknown as number
        this.evaluacionInstanciaService.disableIgnoreModel()

        return this.evaluacionInstanciaService
            .porGenerador(instanciaParams)
            .then((instancias: EvaluacionInstancia[]) => {
                this.loadingDone = false
                this.instancias = instancias
                let alternativaIds = instancias.reduce((a, b) => {
                    return a.concat(b.respuestas.map(r => r.alternativa_id).filter(x => x !== null))
                }, [])
                const paramAlternativas: any = {
                    raw: 1,
                    alternativa: { id: alternativaIds }
                }
                if (alternativaIds.length > 0) {
                    return this.alternativaService.wherePost(paramAlternativas).then((alternativas: Alternativa[]) => {
                        this.procesarData(instancias, alternativas)
                        this.loadingDone = true
                        this.loadingLayout.ready()
                        return total
                    })
                } else {
                    this.procesarData(instancias, [])
                    this.loadingDone = true
                    this.loadingLayout.ready()
                    return total
                }
            })
    }

    procesarData(instancias: EvaluacionInstancia[], alternativas: Alternativa[]) {
        instancias.forEach(i => {
            this.respuestasPorPregunta[i.usuario_id] = {}
            let correctas, incorrectas, omitidas, tiempoTotal, dudosas
            correctas = incorrectas = omitidas = tiempoTotal = dudosas = 0
            i.evaluacion.instrumento.instrumento_preguntas.forEach(ip => {
                const respuesta = i.respuestas.find(r => r.pregunta_id == ip.pregunta_id)
                let alternativa = null
                if (respuesta) {
                    alternativa = alternativas.find(a => a.id == respuesta.alternativa_id)
                    respuesta.alternativa = alternativa
                }
                // const alternativa = alternativas.find(a => )
                const tiempo = Math.round(respuesta.tiempo)
                const correcta = respuesta?.alternativa?.correcta || false
                const omitida = respuesta?.alternativa_id == null
                const dudosa = tiempo < 15 && correcta
                if (omitida) {
                    omitidas += 1
                } else if (correcta) {
                    correctas += 1
                } else {
                    incorrectas += 1
                }
                if (dudosa) {
                    dudosas += 1
                }
                tiempoTotal += tiempo
                this.respuestasPorPregunta[i.usuario_id][ip.pregunta_id] = {
                    correcta: correcta,
                    omitida: omitida,
                    dudosa: dudosa,
                    tiempo: tiempo
                }
            })
            this.respuestasPorPregunta[i.usuario_id]["dudosas"] = dudosas
            this.respuestasPorPregunta[i.usuario_id]["tiempoTotal"] = tiempoTotal
            this.respuestasPorPregunta[i.usuario_id]["correctas"] = correctas
            this.respuestasPorPregunta[i.usuario_id]["incorrectas"] = incorrectas
            this.respuestasPorPregunta[i.usuario_id]["omitidas"] = omitidas
            this.respuestasPorPregunta[i.usuario_id]["totalSugerido"] = correctas - dudosas
            this.respuestasPorPregunta[i.usuario_id]["notaSugerida"] = (correctas - dudosas) * 0.1
        })
    }
}

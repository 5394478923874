<div class="contenedor">
    <!-- Login -->
    <div id="section1">
        <div class="caja caja-inicio">
            <div class="fading-image" [lazyLoad]="landingImage" [offset]="200"></div>
            <div class="caja-inner-margin">
                <h1 class="titulo" *ngIf="titulo">{{ titulo }}</h1>
                <login-standard
                    class="login"
                    [enableContactUs]="false"
                    [enableRegister]="enableRegister"
                    [enableFacebookLogin]="false"
                    [enablePasswordRecovery]="true"
                ></login-standard>
                <br />
            </div>
        </div>

        <footer>
            <div class="footer-col">
                <h3 class="contactanos">Contáctanos</h3>
                <ul>
                    <li>
                        <p>
                            <fa name="envelope-o"></fa>
                            &nbsp;{{ config.plataforma.info.contacto.mail }}
                        </p>
                    </li>
                </ul>
            </div>
        </footer>
    </div>
</div>

<loading-layout>
    <div class="legend">
        <ul>
            <li>
                <fa name="check" class="icono correcta"></fa>
                Correctas
            </li>
            <li>
                <fa name="times" class="icono incorrecta"></fa>
                Incorrectas
            </li>
            <li>
                <fa name="ban" class="icono omitida"></fa>
                Omitidas
            </li>
            <li>
                <fa name="question" class="icono dudosa"></fa>
                dudosas
            </li>
            <li>
                <ogr-icon [name]="'clock'" class="ogricon"></ogr-icon>
                Tiempo (hh:mm:ss)
            </li>
        </ul>
    </div>

    <div class="tabla-container" *ngIf="loadingDone">
        <table class="table table-striped table-hover table-bordered">
            <tr>
                <th class="usuario text-center">Usuario</th>
                <th *ngFor="let p of dummyPreguntas; let i = index" class="text-center">P{{ i + 1 }}</th>
                <th class="text-center">Acciones</th>
            </tr>
            <tbody>
                <tr *ngFor="let i of instancias">
                    <td>
                        <div class="container text-center">
                            <div class="row">
                                <div class="nombre border-bottom">
                                    <p>
                                        <fa name="user" class="icono"></fa>
                                        {{ i.usuario.nombre }} {{ i.usuario.apellido_paterno }}
                                    </p>
                                </div>
                                <div>
                                    <div class="row no-margin border-bottom">
                                        <div class="col-md-4">
                                            <ogr-icon [name]="'clock'" class="ogricon"></ogr-icon>
                                            Tiempo
                                            <br />
                                            {{
                                                respuestasPorPregunta[i.usuario_id]["tiempoTotal"] | secondsToTime: "s"
                                            }}
                                        </div>
                                        <div class="col-md-4">
                                            Puntaje
                                            <br />
                                            # {{ i.correctas }}
                                        </div>
                                        <div class="col-md-4">
                                            Nota
                                            <br />
                                            # {{ i.calificacion }}
                                        </div>
                                    </div>
                                    <div class="row no-margin">
                                        <div class="col-md-4">
                                            <div class="detalle row">
                                                <div class="col-md-6">
                                                    <fa name="check" class="icono correcta"></fa>
                                                    <br />
                                                    {{ respuestasPorPregunta[i.usuario_id]["correctas"] }}
                                                </div>
                                                <div class="col-md-6">
                                                    <fa name="times" class="icono incorrecta"></fa>
                                                    <br />
                                                    {{ respuestasPorPregunta[i.usuario_id]["incorrectas"] }}
                                                </div>
                                                <div class="col-md-6">
                                                    <fa name="ban" class="icono omitida"></fa>
                                                    <br />
                                                    {{ respuestasPorPregunta[i.usuario_id]["omitidas"] }}
                                                </div>
                                                <div class="col-md-6">
                                                    <fa name="question" class="icono dudosa"></fa>
                                                    <br />
                                                    {{ respuestasPorPregunta[i.usuario_id]["dudosas"] }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            Puntaje Sugerido
                                            <br />
                                            # {{ respuestasPorPregunta[i.usuario_id]["totalSugerido"] }}
                                        </div>
                                        <div class="col-md-4">
                                            Nota Sugerida
                                            <br />
                                            # {{ respuestasPorPregunta[i.usuario_id]["notaSugerida"] }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                    <ng-container *ngFor="let ip of i.evaluacion.instrumento.instrumento_preguntas">
                        <ng-container *ngIf="respuestasPorPregunta[i.usuario_id][ip.pregunta_id]['omitida']">
                            <td class="text-center omitida">
                                <p>
                                    <fa name="ban" class="icono omitida"></fa>
                                </p>
                            </td>
                        </ng-container>
                        <ng-container *ngIf="!respuestasPorPregunta[i.usuario_id][ip.pregunta_id]['omitida']">
                            <td
                                class="text-center"
                                [ngClass]="{
                                    correcta:
                                        respuestasPorPregunta[i.usuario_id][ip.pregunta_id]['correcta'] &&
                                        !respuestasPorPregunta[i.usuario_id][ip.pregunta_id]['dudosa'],
                                    incorrecta: !respuestasPorPregunta[i.usuario_id][ip.pregunta_id]['correcta'],
                                    dudosa: respuestasPorPregunta[i.usuario_id][ip.pregunta_id]['dudosa']
                                }"
                            >
                                <div>
                                    <p>
                                        <fa
                                            *ngIf="respuestasPorPregunta[i.usuario_id][ip.pregunta_id]['correcta']"
                                            name="check"
                                            class="icono correcta"
                                        ></fa>
                                        <fa
                                            *ngIf="!respuestasPorPregunta[i.usuario_id][ip.pregunta_id]['correcta']"
                                            name="times"
                                            class="icono incorrecta"
                                        ></fa>
                                    </p>
                                </div>
                                <div>
                                    <p>
                                        <ogr-icon [name]="'clock'" class="ogricon"></ogr-icon>
                                        <br />
                                        {{
                                            respuestasPorPregunta[i.usuario_id][ip.pregunta_id]["tiempo"]
                                                | secondsToTime: "s"
                                        }}
                                    </p>
                                </div>
                            </td>
                        </ng-container>
                    </ng-container>
                    <td class="text-center">
                        <a
                            class="btn btn-primary"
                            [routerLink]="[rutaInstancias, i.id]"
                            [queryParams]="{ nombre: i.usuario.nombre + ' ' + i.usuario.apellido_paterno }"
                        >
                            Ver
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <br />
    <paginator *ngIf="generadorInstrumento" [getList]="getInstancias"></paginator>
</loading-layout>

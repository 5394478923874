import { LoggedLayoutService } from "@puntaje/shared/cui"
import { Component, ViewChild } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { GeneradorInstrumento, Pregunta, Preguntas } from "@puntaje/nebulosa/api-services"
import {
    Estadisticas,
    EvaluacionInstancias,
    InstrumentoPregunta,
    InstrumentoPreguntas
} from "@puntaje/puntaje/api-services"

@Component({
    templateUrl: "./estadistica_anuies.component.html"
})
export class EstadisticaAnuiesComponent {
    data
    dataRespuestas
    instrumentoIds: number[]
    instanciaIds: number[]
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(
        protected evaluacionInstanciaService: EvaluacionInstancias,
        protected preguntasService: Preguntas,
        protected instrumentoPreguntasService: InstrumentoPreguntas,
        protected estadisticasService: Estadisticas,
        protected loggedLayoutService: LoggedLayoutService
    ) {}

    setPreguntas(data) {
        this.dataRespuestas = data
        this.loadingLayout.ready()
    }

    async setData(generadorInstrumento: GeneradorInstrumento) {
        let params = {
            evaluacion_instancia: {
                oficial: 1
            },
            include: "[usuario,respuestas,evaluacion:[instrumento:instrumento_preguntas]]",
            generador_instrumento_id: generadorInstrumento.id
        }
        let res = await this.evaluacionInstanciaService.porGeneradorStats(params)

        let paramsInstrumentoPreguntas = {
            instrumento_pregunta: {
                instrumento_id: res["instrumento_ids"]
            },
            respuesta: {
                evaluacion_instancia_id: res["instancia_ids"]
            },
            include: "respuestas:alternativa",
            sort_by: "orden",
            order: "asc"
        }

        this.instrumentoPreguntasService
            .where(paramsInstrumentoPreguntas)
            .then(async (instrumentoPreguntas: InstrumentoPregunta[]) => {
                const preguntaIds = instrumentoPreguntas.map(ip => ip.pregunta_id)

                let estadisticaPreguntas = (await this.estadisticasService.wherePost({
                    collection: "EstadisticaPregunta",
                    estadistica: { pregunta_id: preguntaIds }
                })) as any[]
                let estadisticaPreguntasByPreguntaId = (estadisticaPreguntas as any).groupBy(ep => ep.pregunta_id)

                this.data = preguntaIds.map(pid => {
                    let data: any = {}
                    data.numero = pid
                    let respuestas = instrumentoPreguntas
                        .filter(ip => ip.pregunta_id == pid)
                        .map(ip => {
                            return {
                                ip: ip,
                                respuestas: ip.respuestas,
                                length: ip.respuestas.length,
                                incorrectas: ip.respuestas.filter(r => r.alternativa && !r.alternativa.correcta).length
                            }
                        })
                    let numeroRespuestas = respuestas.reduce((a, b) => a + b.length, 0)
                    let numeroIncorrectas = respuestas.reduce((a, b) => a + b.incorrectas, 0)
                    data.pregunta_id = pid

                    data.incorrectas = numeroIncorrectas / numeroRespuestas

                    return data
                })

                this.setPreguntas(this.data)
            })
    }

    ngOnInit() {
        this.loggedLayoutService.setOptions({ hideTitleOnPrint: true })
    }
}
